#side_bar {
  background-color: $schp-primary-color; //$rc-dark-bg-color;
  color: $white;
  padding: 1rem 0.5rem 1rem 1rem;

  ul.menu {
    padding-bottom: 1rem;

    a {
      padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    }
  }

  a {
    color: $white;
    &:hover {
      color: #ffffaa;
    }
  }
}
