form.company_form {
  ul {
    list-style: none;
    margin-left: 0;
  }
}

a.small-link {
  font-size: 0.875rem;
  opacity: 0.75;
}
