
div.success_story {

  padding: 1rem;

  div.content {
    border: solid 1px #5b80b2;
    padding: 1rem;
    height: 100%;
    position: relative;
    border-radius: 3px;

    &:hover {
      background-color: #effaff;
    }

    div.author {
      margin-top: 1rem;
    }

    div.download {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }

    ul {
      margin-left: 1rem;
      list-style: none;
    }
  }
}
