a.open_year {
  font-weight: bold;
}

form.outset {
  margin: 1rem 0 0 1rem;

  button {
    margin-bottom: 0;
  }
}

table.my_reports {

  tbody {

    tr.open_year {
      td {
        font-weight: bold;
      }
    }
  }

  tr.deadline {
    background-color: lighten($schp-tertiary-color, 10%);
    color: darken($schp-primary-color, 10%);
  }

}
