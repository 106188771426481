html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 14em;
}

.page {
  // this is used on the main container for the main content
  padding-top: 2rem;
}

.subdued {
  color: #666666;
}

table {
  tr {
    th {
      text-align: left;
    }
  }

  td.warning {
    background-color: #eeaaaa;
  }

  &.condensed {
    width: inherit;
    td, th {
      padding: 0 1rem 0 0;
    }
  }

  &.noborder {
    border: none;
    tbody {
      border: none;
    }
    thead {
      border: none;
    }
    tr {
      border: none;
    }
    td {
      border: none;
    }

  }

}

.inline_block {
  display: inline-block;
}

// no bottom margin for lists inside a table
td ul {
  margin-bottom: 0;
}

p.note {
  font-style: italic;
}


button[type=submit] {
  // use default foundation primary button for all buttons by default
  @include button();
}

button.inline {
  margin-bottom: 0;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.number {
  text-align: right;
}

input.number {
  max-width: 7em;
}

.small_caps {
  font-variant: small-caps;
}

#menu_div {
  position: relative;
}

#main_logo {

  @include breakpoint(medium) {
    position: absolute;
    left: 1rem;
    top: 0.5rem;
  }

  @include breakpoint(small only) {
    padding: 10px;
  }

  img {
    height: 56px;
    width: 217px;
  }
}

.messages {
  margin-top: 2rem;
}

table.shrink {
  width: auto;
}

a.action {
  padding: 0 0.375rem;
}

span.green {
  color: #3adb76;
}

span.red {
  color: #dc3b53;
}

span.disabled {
  color: #777777;
}

.italic {
  font-style: italic;
}
