div.company_list {

  margin-top: 2rem;

  div.company_header {

    margin-bottom: 0.375rem;

    h2 {
      margin-top: 0.375rem;
      font-size: 1.5rem;
    }

    .thumbnail {
      box-shadow: none;
      img {
        height: 32px;
        width: auto;
      }

      &.empty {
        box-shadow: none;
      }
    }

  }
}
