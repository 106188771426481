#stats_table {
  select {
    margin-bottom: 0;
  }

  th.sublevel {
    padding-left: 1rem;
    font-size: 87.5%;
    font-weight: normal;
  }

}

table.stats_results {

  font-size: 0.875rem;

  thead {
    th {
      font-variant: small-caps;
      background-color: #333333;
      color: $white;
      text-align: center;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background: rgba(220, 220, 220, 0.3);
    }

    td:nth-of-type(even) {
      background: rgba(220, 220, 220, 0.3);
    }

    th:nth-of-type(odd) {
      background: rgba(220, 220, 220, 0.6);
    }

    td.sum, tr.sum {
      background-color: rgba(0, 100, 255, 0.1);
      font-style: italic;
    }

  }


}
