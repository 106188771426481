div#report {

  input {
    margin: 0;
    padding: 0.125rem 0.5rem;
    height: auto;

    &:read-only {
      background-color: #f3f3f3;
      color: #666666;
    }

    &[type=text] {
      display: inline;
    }
  }

  td, th {
    border-right: $table-border;
    position: relative;
  }

  .previous {
    color: #999999;
    font-size: 0.875rem;
  }

  .measure {
    font-size: 0.875rem;
  }

  td.comparison {
    font-size: 0.875rem;
    //border-left: $table-border;
  }

  span.warning {
    background-color: $warning-color;
    padding: 0.25rem;
    color: white;
  }

  span.error {
    background-color: $input-error-color;
    padding: 0.25rem;
    color: white;
  }

  tr {
    position: relative;

    &.error {
      background-color: #ffaaaa;
    }
  }

  span.validation {
    font-variant: normal;
    font-size: 0.875rem;
    position: absolute;
    left: 8rem;
    top: 0.375rem;
    display: block;
    min-width: 10rem;
    background-color: #ff4444;
    color: white;
    text-align: left;
    z-index: 1000;
    padding: 0.125rem 0.5rem;

    &:before {
      //content: '<< '
    }

  }

}
