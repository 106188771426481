form.password_change {
  input {
    max-width: 20rem;
  }

  ul.errorlist {
    list-style: none;
    margin-left: 0;
    color: #aa3333;
  }

}
