table#indicators {

  tr.computed {
    background-color: #f9f9f9;
    color: #222222;
    th {
      font-style: italic;
    }
  }

  tr.header, thead tr {
    background-color: #444444;
    color: white;
  }

  tr.inactive {
    background-color: #eeeeee;
    color: #888888;
    text-decoration: line-through;
  }

}
