$footer-visible-height: 7rem;
$footer-total-height: 7rem;
$footer-border-width: 0.5rem;

.footer {
  position: absolute;
  bottom: ($footer-visible-height - $footer-total-height + $footer-border-width);
  width: 100%;
  /* Set the fixed height of the footer here */
  height: $footer-total-height;
  //background-color: $rc-dark-bg-color;
  font-size: 110%;
  color: $rc-primary-color; //$rc-inverse-text-color;
  padding-top: 1.65rem;
  border-top: solid $footer-border-width $schp-tertiary-color;

  p {
    //padding-top: 30px;
    //margin-bottom: .5rem;
  }

  a {
    color: $schp-primary-color; //$rc-inverse-text-color;
  }

  img.company_logo {
    max-height: 4rem;
    float: right;
    //margin-top: 1.5em;
  }
}
