table.report {

  margin-bottom: 2rem;

  thead {
    background-color: #444444;
    color: $white;

    tr:hover {
      background-color: inherit;
      color: inherit;
    }
  }


  tbody {

    th {
      font-weight: normal;
    }

    th, td {
      padding: 0.125rem 0.65rem 0.125rem 0.25rem;
    }

    tr.group_header {
      font-variant: small-caps;
      background-color: #dddddd;
      //background-color: #88ee88;
      .subdued {
        font-variant: normal;
      }
      th {
        font-weight: bold;
      }

      &.secondary {
        background-color: #eeeeee;
        //background-color: #8888ee;
      }
    }

    tr:hover, tr.group_header:hover {
      background-color: #ddffdd;
    }

    tr:focus-within, tr.group_header:focus-within {
      background-color: #ffffdd;
    }
  }
}
