#topbar {

  border-bottom: solid 1px $schp-tertiary-color;
  padding: 0;

  @include breakpoint(medium) {
   margin-left: 280px;
  }
}

ul.menu {
  li {
    //padding: 1rem;

    &.active {

      a {
        background-color: $white;
        color: $primary-color;
        border-bottom: solid 2px $schp-tertiary-color;
        border-top: solid 2px $schp-tertiary-color;
      }
    }

    a {
      padding: 1rem 1rem 0.935rem 1rem;
    }

  }

}

.title-bar {
  background-color: $schp-primary-color;
  //color: $schp-primary-color;
}
